<script setup lang="ts">
import type { InitDataResponseData } from "@/types";

const props = defineProps<{
	data: InitDataResponseData;
}>();

const route = useRoute();
const isGuest = useIsGuest();
const { isMobile } = useDevice();
const { data: bonusesData } = useGetBonusesData();
const { depositStreakIsActive, prizeDropsIsActive } = useFeaturesIsActive();
const { isActiveMiniWheel } = useDepositStreakData({
	immediate: !isGuest.value && depositStreakIsActive.value
});

const isDailyWheel = computed(
	() =>
		!props.data?.isGuest &&
		(bonusesData.value?.dailyWheel?.available || bonusesData.value?.dailyWheel?.reasonType === "phone")
);
const isCoinDrops = computed(() => prizeDropsIsActive.value && route.path !== "/coin-drops");
</script>

<template>
	<div class="widgets-fixed-side">
		<MDailyWheelWidget v-if="isDailyWheel" />
		<MCoinDropsMiniGift v-if="isCoinDrops" />
		<MWheelOfWinsMiniFixedWheel v-if="depositStreakIsActive && isActiveMiniWheel && !isMobile" />
		<template v-if="isMobile">
			<MPrizeHub v-if="data?.isGuest" />
			<ONotificationCenter v-else class="notification-center out-of-menu" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
.widgets-fixed-side {
	position: fixed;
	z-index: 1998;
	right: 0;
	bottom: 87px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: gutter(1);
	@include media-breakpoint-up(lg) {
		bottom: 25vh;
		& > * {
			cursor: pointer;
		}
	}
}
</style>
